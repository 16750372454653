import React, {useEffect, useState} from 'react';
import $ from "jquery";
import moment from "moment";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import {
    GET_COLLECTION_DATA
} from "../../../api";
import cogoToast from "cogo-toast";
import {getUser, getPreSignedUrl, collector, uploadPhoto} from "../../../api/app";
import {capitalize} from "../../../utils/capitalize";
import {useSelector} from "react-redux";
import ViewGMap from "../../../components/map/ViewGMap";
const Buffer = require('buffer/').Buffer;

const Data = (props) => {

    const token = localStorage.getItem('jwtToken');

    const id = props.match.params.id;
    const auth = useSelector((state) => state.auth.user);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [openMap, setOpenMap] = useState(false);
    const [coordinate, setCoordinate] = useState({});
    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [logo_image, set_logo_image] = useState(null);
    const [images, setImages] = useState([]);
    const [datatable] = useState({
        waste: false,
        address: false,
        credit: false,
        report: false
    });

    useEffect(() => {
        getUser(id).then((response) => {
            if(!response.error) {
                setUser(response.payload);
                setLoading(false);
            }
        });
    }, []);

    const wasteDatatable = () => {
        if(datatable.waste) return false;
        datatable.waste = true;
        const table = $('#waste_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Waste Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "Waste",
                        fieldname: "name",
                        filter: {
                            user: user._id
                        },
                        pageSize: data.start,
                        format: 'json',
                        populate: [
                            {
                                path: 'product',
                                model: 'product',
                            },
                            {
                                path: 'transaction',
                                model: 'transaction',
                            },
                            {
                                path: 'address.property',
                                model: 'property',
                            }
                        ],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return capitalize(data.type);
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return `${user.country.currency.symbol}${data.meta.amount*data.bin*data.total_month}`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return capitalize(data.product.type);
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return {
                            "paid" : `<span class="badge badge-success tx-white">${capitalize(data.status)}</span>`,
                            "credit" : `<span class="badge badge-warning tx-white">${capitalize(data.status)}</span>`,
                            "expired" : `<span class="badge badge-danger tx-white">${capitalize(data.status)}</span>`
                        }[data.status];
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return data.status !== "pending" ? data.due_date.str > moment(new Date()).format("YYYY/MM/DD HH:mm") ? `<span class="tx-purple">${moment(data.due_date.iso).format("Do MMM, YYYY")}</span>` : `<span class="tx-danger">${moment(data.due_date.iso).format("Do MMM, YYYY")}</span>` : `<span class="badge badge-warning tx-white">Pending</span>`;
                    },
                    "targets": 5
                }
            ]
        });

        $('#waste_table tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function format(d) {
            // `d` is the original data object for the row
            const status = (data) => {
                return {
                    "renew-subscription" : `<span class="text-capitalize">Renew</span>`,
                    "credit-subscription" : `<span class="text-capitalize">Credit</span>`,
                    "subscription" : `<span class="text-capitalize">New</span>`
                }[data?.transaction?.metadata?.type] || `<span class="text-capitalize">Subscription<span class="text-danger">Unknown</span></span>`;
            }

            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>Total Amount:</td>' +
                '<td>' +
                auth.user.country.currency.symbol + d.raw.meta.amount*d.raw.total_month*d.raw.bin +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Number of Bin:</td>' +
                '<td>' +
                d.raw.bin +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Address:</td>' +
                '<td>' +
                `${d.raw.address.unit_number}, ${d.raw.address.meta.street_address}` +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Number of Month:</td>' +
                '<td>' +
                d.raw.total_month +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Type:</td>' +
                '<td>' +
                capitalize(d.raw.address.property.type) +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Transaction #:</td>' +
                '<td>' +
                (d.raw.status === "credit" ? "Pending" : `${d.raw.transaction.txn_number.toUpperCase()} - ${status(d.raw)}`) +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Date Issued:</td>' +
                '<td>'+moment(d.raw.date.iso).format("Do MMM, YYYY")+'</td>' +
                '</tr>' +
                '</table>'
            );
        }
    }

    const addressDatatable = () => {
        if(datatable.address) return false;
        datatable.address = true;
        let addresses = [];

        user.addresses.map((item) => {
            addresses.push([capitalize(item.unit_number), capitalize(item.meta.street_address), capitalize(item.meta.suburb), capitalize(item.meta.city), capitalize(item.meta.state), item._id, item.location.coordinates[0], item.location.coordinates[1]])
        });

        let table = $('#address_table').DataTable({
            responsive: true,
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            pageLength: 10,
            data: addresses,
            columns: [
                { title: 'Unit #' },
                { title: 'Street Address' },
                { title: 'Area' },
                { title: 'City' },
                { title: 'State/Province' },
                { title: 'Actions' }
            ],
            columnDefs: [
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link view-map"><i class="fa fa-map"></i> View Map</a></nav>`
                    },
                    "targets": 5
                }
            ]
        });

        table.on('click', '.view-map', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            setCoordinate({lat: extract_td[6], lng: extract_td[7]});

            $("#modalMap").modal({ backdrop: 'static', focus: false, show: true });
            setOpenMap(true);
        });
    }

    const ratingDatatable = () => {
        if(datatable.rating) return false;
        datatable.rating = true;
        let table = $('#rating_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Rating Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "feedback",
                        fieldname: "name",
                        filter: {
                            user: user._id,
                            collector: auth.user.sub
                        },
                        pageSize: data.start,
                        populate: [
                            {
                                path: 'user',
                                model: 'user'
                            },
                            {
                                path: 'collector',
                                model: 'collector'
                            }
                        ],
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `${capitalize(data.user.first_name)} ${capitalize(data.user.last_name)}`;;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return {
                            1 : `<div class="d-flex">
                                    <strong class="tx-12 tx-rubik mg-r-5">1.0</strong>
                                      <div>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                      </div>
                                    </div>
                                </div>`,
                            2 : `<div class="d-flex">
                                    <strong class="tx-12 tx-rubik mg-r-5">2.0</strong>
                                      <div>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                      </div>
                                    </div>
                                </div>`,
                            3 : `<div class="d-flex">
                                    <strong class="tx-12 tx-rubik mg-r-5">3.0</strong>
                                      <div>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                      </div>
                                    </div>
                                </div>`,
                            4 : `<div class="d-flex">
                                    <strong class="tx-12 tx-rubik mg-r-5">4.0</strong>
                                      <div>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                      </div>
                                    </div>
                                </div>`,
                            5 : `<div class="d-flex">
                                    <strong class="tx-12 tx-rubik mg-r-5">5.0</strong>
                                      <div>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                      </div>
                                    </div>
                                </div>`
                        }[data.rate];
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return `<div class="d-flex">
                            <div class="avatar wd-20 ht-20"><img src="${data.collector.logo_uri}" class="rounded-circle" alt="logo"></div>
                            <span class="pd-x-5">${capitalize(data.collector.name)}</span>
                        </div>`;
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY");
                    },
                    "targets": 4
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only">-- No Action --</nav>`
                    },
                    "targets": 5
                }
            ]
        });

        $('#rating_table tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function format(d) {
            // `d` is the original data object for the row
            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>Feedback:</td>' +
                '<td>' +
                d.raw.feedback +
                '</table>'
            );
        }
    }

    const handleModalProduct = () => {
        setEditForm(false);
        $('#type').prop("disabled", false).prop("required", true);
        $('#product_form').parsley();
        $("#modalProduct").modal({ backdrop: 'static', focus: false, show: true });
    };

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const handleFileUpload = (event) => {
        if(event.target.files[0] !== undefined && $('#logo_image').parsley().isValid() !== false) {
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                set_logo_image(reader.result);
            };
            reader.onerror = () => {
                set_logo_image(null);
            };
        }
    }

    const handleCollectorSubmit = async(e) => {
        e.preventDefault();
        setStateReady(true);

        let data = {
            id: user._id,
            name : $("#name").val(),
            address: $("#address").val(),
            logo_uri : logo_image !== null ? await submitPhoto() : null,
            email : $("#email").val(),
            mobile_number : $("#mobile").val(),
            tax_id : $("#tax").val()
        }

        collector("put", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                resetForm("modalCollector", "collector_form");
                setUser(result.payload);
            }
        })

        return false;
    }

    const submitPhoto = () => {
        return getPreSignedUrl().then(async (response) => {
            if (response.error) {
                return false;
            }

            const config = {
                headers: {
                    'Content-Type': 'image/jpeg; charset=utf-8',
                    'x-amz-acl': 'public-read'
                }
            };

            const buffer = new Buffer.from(logo_image.replace(/^data:image\/\w+;base64,/, ""), 'base64')

            return uploadPhoto(response.payload.url, buffer, config).then((result) => {
                if (!result.error) {
                    return result.payload.config.url.replace(/\?.*$/g,"");
                }

                return null;
            });
        })
    };

    if(!loading) {
        return (
            <div className="content-body">
                <div className="container-fluid pd-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                    <li className="breadcrumb-item"><a href="#">Collector</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Details</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="d-none d-md-block">
                            {user.gender ? <span className="badge badge-success tx-white">Active</span> :
                                <span className="badge badge-danger tx-white">Inactive</span>}
                        </div>
                    </div>

                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div className="media">
                            <div
                                className="wd-100 ht-100 bg-ui-04 rounded d-flex align-items-center justify-content-center bg-white">
                                {user.photo_uri ?
                                    <img src={user.photo_uri} className="img-fit-cover rounded-5" alt="logo"/> :
                                    <span className="link-03 lh-0 text-center"><i style={{fontSize: 50}}
                                                                                  className="icon ion-md-camera"></i><p
                                        className="text-center tx-color-03 mt-2 tx-13">No Photo</p></span>}
                            </div>
                            <div className="media-body pd-l-25 my-auto">
                                <h5 className="mg-b-5 text-capitalize">{user.first_name} {user.last_name}</h5>
                                <p className="mg-b-3 text-capitalize">
                                    <span className="tx-medium tx-color-02 text-capitalize">{user.gender}</span><br/>
                                    {user.country.name}
                                </p>
                                <span className="d-block tx-13 tx-color-03">{user.mobile_number}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row row-xs">
                        <div className="col-md-12 mg-t-10">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <div>
                                    <ul id="nav_basic" className="nav nav-line nav-fill tx-medium">
                                        <li className="nav-item"><a href="#basic " className="nav-link active"
                                                                    data-toggle="tab"><i
                                            className="ion-md-information-circle"/> User Info</a></li>
                                        <li className="nav-item"><a href="#address" className="nav-link"
                                                                    data-toggle="tab" onClick={addressDatatable.bind()}><i
                                            className="ion-md-locate"/> Addresses</a></li>
                                        <li className="nav-item"><a href="#waste" className="nav-link" data-toggle="tab"
                                                                    onClick={wasteDatatable.bind()}><i
                                            className="ion-md-trash"/> Wastes</a></li>
                                        <li className="nav-item"><a href="#rating" className="nav-link"
                                                                    data-toggle="tab"
                                                                    onClick={ratingDatatable.bind()}><i
                                            className="ion-md-star"/> Rating</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="basic" className="tab-pane fade active show">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <p><strong>Mobile #:</strong> {user.mobile_number}</p>
                                            <p><strong>Gender:</strong> {user.gender}</p>
                                            <p><strong>Country:</strong> {capitalize(user.country.name)}</p>
                                            <p><strong>Last
                                                Login:</strong> {moment(user.last_login.str).format("Do MMM, YYYY")}</p>
                                            <p><strong>Date
                                                Created:</strong> {moment(user.date.str).format("Do MMM, YYYY")}</p>
                                        </div>
                                    </div>
                                </div>

                                <div id="waste" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Wastes</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $('#waste_table').DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="waste_table" className="table" style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th className="wd-20p">Type</th>
                                                            <th className="wd-20p">Amount</th>
                                                            <th className="wd-20p">Product</th>
                                                            <th className="wd-20p">Status</th>
                                                            <th className="wd-20p">Expiry Date</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="address" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Addresses</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $('#address_table').DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="address_table" className="table table-hover"
                                                           style={{width: "100%"}}></table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="credit" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Credits/Wallet</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $('#credit_table').DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={handleModalProduct.bind()}><i
                                                            className="icon ion-md-add"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="credit_table" className="table table-hover"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Amount</th>
                                                            <th className="wd-20p">Points</th>
                                                            <th className="wd-20p">Status</th>
                                                            <th className="wd-20p">Date</th>
                                                            <th className="wd-20p">Action</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="rating" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div
                                                    className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Rating</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $('#rating_table').DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="rating_table" className="table table-hover"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th className="wd-20p">Customer</th>
                                                            <th className="wd-20p">Rate</th>
                                                            <th className="wd-20p">Collector</th>
                                                            <th className="wd-20p">Date</th>
                                                            <th className="wd-20p">Action</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog"
                         aria-labelledby="confirmation" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content tx-14">
                                <div className="modal-header">
                                    <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="mg-b-0 data-message">Empty</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id="delete-data" className="btn btn-success btn-block">Yes,
                                        Delete
                                    </button>
                                    <button type="button" className="btn btn-danger btn-block mt-0"
                                            data-dismiss="modal">Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalCollector" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15"
                                       data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button"
                                                                   className="close pos-absolute t-15 r-15"
                                                                   data-dismiss="modal"
                                                                   onClick={resetForm.bind(null, "modalCollector", "collector_form")}
                                                                   aria-label="Close"><span aria-hidden="true">×</span></a>
                                        <h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Garbage Collector" : "New Garbage Collector"}</h4>
                                        <p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} garbage
                                            collector information.</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="collector_form" className="parsley-style-1"
                                          onSubmit={handleCollectorSubmit.bind()}>
                                        <div id="nameWrapper" className="form-group parsley-input">
                                            <label>Company Name<span className="tx-danger">*</span></label>
                                            <input id="name" type="text" className="form-control"
                                                   placeholder="Enter company name"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#nameWrapper" required/>
                                        </div>

                                        <div id="logoWrapper" className="form-group parsley-input">
                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Company
                                                Logo</label>
                                            <input id="logo_image" type="file" className="form-control"
                                                   onChange={handleFileUpload.bind()}
                                                   data-parsley-filemaxmegabytes="1"
                                                   data-parsley-trigger="change"
                                                   data-parsley-filemimetypes="image/jpeg, image/jpg, image/png"
                                                   accept="image/jpeg, image/jpg, image/png"
                                                   data-parsley-class-handler="#logoWrapper"
                                            />
                                        </div>

                                        <div id="emailWrapper" className="form-group parsley-input">
                                            <label>Email<span className="tx-danger">*</span></label>
                                            <input id="email" type="email" className="form-control"
                                                   placeholder="Enter email address"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#emailWrapper" required/>
                                        </div>

                                        <div id="mobileWrapper" className="form-group parsley-input">
                                            <label>Mobile Number<span className="tx-danger">*</span></label>
                                            <input id="mobile" type="number" className="form-control"
                                                   placeholder="Enter mobile number"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#mobileWrapper" required/>
                                        </div>

                                        <div id="addressWrapper" className="form-group parsley-input">
                                            <label>Physical Address<span className="tx-danger">*</span></label>
                                            <input id="address" type="text" className="form-control"
                                                   placeholder="Enter physical address"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#addressWrapper" required/>
                                        </div>

                                        <div id="taxWrapper" className="form-group parsley-input">
                                            <label>Tax ID<span className="tx-danger">*</span></label>
                                            <input id="tax" type="text" className="form-control"
                                                   placeholder="Enter tax ID or TPIN #"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#taxWrapper" required/>
                                        </div>

                                        {stateReady ? <button disabled className="btn btn-success btn-block"><span
                                            className="spinner-border spinner-border-sm mg-r-10" role="status"
                                            aria-hidden="true"/>Please Wait</button> : editForm ?
                                            <button className="btn btn-success btn-block">Update Collector</button> :
                                            <button className="btn btn-success btn-block">Create Collector</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalReport" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15"
                                       data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button"
                                                                   className="close pos-absolute t-15 r-15"
                                                                   data-dismiss="modal" aria-label="Close"><span
                                        aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">Report</h4>
                                        <p className="tx-13 tx-color-02 mg-b-0">Reported By: <span
                                            id="tx-user">---</span></p></div>
                                </div>
                                <div className="modal-body pd-sm-b-40 pd-sm-x-30">
                                    <p><strong>Description:</strong> <span id="tx-description">---</span></p>
                                    <p><strong>Type:</strong> <span id="tx-type">---</span></p>
                                    <p><strong>Location:</strong> <span id="tx-location">---</span></p>
                                    <p><strong>Status:</strong> <span id="tx-status">---</span></p>
                                    <p><strong>Report Date:</strong> <span id="tx-date">---</span></p>
                                    {images.length > 0 ? <PhotoProvider>
                                        <p className="tx-primary">Click Photos Below</p>
                                        <div className="img-group float-left">
                                            {images.map((item, index) => (
                                                <PhotoView key={index} src={item}>
                                                    <img src={item} className="img wd-60 ht-60 rounded-circle" alt=""/>
                                                </PhotoView>
                                            ))}
                                        </div>
                                    </PhotoProvider> : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalMap" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15"
                                       data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="#" role="button"
                                                                   className="close pos-absolute t-15 r-15"
                                                                   data-dismiss="modal" aria-label="Close"><span
                                        aria-hidden="true">×</span></a>
                                        <h4 className="tx-18 tx-sm-20 mg-b-2">View Map</h4>
                                        <p className="tx-13 tx-color-02 mg-b-0">Map information.</p>
                                    </div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    {openMap ? <ViewGMap coordinate={coordinate}/> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="content-body">
            <div className="container d-flex justify-content-center ht-100p">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p className="mg-t-10">Collecting Data</p>
                </div>
            </div>
        </div>
    )
};

export default Data;
